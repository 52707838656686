import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Heading } from 'components/Headings/Heading';
import { heroV4 } from './HeroV4.module.css';

const heroImg = {
	1: (
		<StaticImage
			src='../../../../assets/images/same-day-dentures/partial-dentures-mix.jpg'
			alt='same-day partial dentures near you'
			width={800}
			height={559}
		/>
	),
	2: (
		<StaticImage
			src='../../../../assets/images/same-day-dentures/upper-dentures.jpg'
			alt='upper dentures in one day'
			width={800}
			height={559}
		/>
	),
	3: (
		<StaticImage
			src='../../../../assets/images/same-day-dentures/same-day-lower-dentures.jpg'
			alt='lower dentures immediate same-day near me'
			width={800}
			height={559}
		/>
	),
	4: (
		<StaticImage
			src='../../../../assets/images/same-day-dentures/dentures-price-near-you.jpg'
			alt='Dentures cost at the dental office in your area'
			width={800}
			height={559}
		/>
	),
	5: (
		<StaticImage
			src='../../../../assets/images/same-day-dentures/denture-care-instructions.jpg'
			alt='How to care for your immediate dentures?'
			width={800}
			height={559}
		/>
	),
	6: (
		<StaticImage
			src='../../../../assets/images/same-day-dentures/partial-metal-dentures.jpg'
			alt='fast and affordable same-day dentures and partials'
			width={800}
			height={559}
		/>
	),
	7: (
		<StaticImage
			src='../../../../assets/images/same-day-dentures/getting-economy-dentures-near-me.png'
			alt='Latest dental technology'
			width={800}
			height={559}
		/>
	),
};

export const HeroV4 = ({ title, description, imgNumber }) => {
	const config = {
		id: '',
		type: 'h2',
		align_desktop: 'center',
		align_mobile: 'center',
		title,
	};

	return (
		<div className={heroV4}>
			{heroImg[imgNumber]}
			<Heading blok={config} classes='mt-2 mb-1 mb-1--mobile mt-1--mobile' />
			<p className='txt-center c-black mt-1 mb-2 mb-1--mobile mt-1--mobile'>{description}</p>
		</div>
	);
};
